import { defineStore } from 'pinia'

type SupabaseClientType = ReturnType<typeof useSupabaseClient>

export const useUserOnlineStatusStore = defineStore('userOnlineStatus', () => {
  // --- state ---
  const supabase = useSupabaseClient<SupabaseClientType>()
  const onlineUsers = ref<Set<string>>(new Set())
  let channelInstance: ReturnType<typeof subscribeToOnlineStatus> | null = null

  //   -- getters --
  const isUserOnline = computed(() => (userId: string | undefined) => userId ? onlineUsers.value.has(userId) : false)

  // --- actions ---
  function subscribeToOnlineStatus() {
    const channel = supabase.channel('online-users')

    const subscription = channel
      .on('presence', { event: 'sync' }, () => {
        const newState = channel.presenceState()
        onlineUsers.value = new Set(Object.values(newState).flatMap(presence => presence.map((p: any) => p.user_id)))
      })
      .on('presence', { event: 'join' }, ({ newPresences }) => {
        newPresences.forEach((presence: any) => {
          onlineUsers.value.add(presence.user_id)
        })
      })
      .on('presence', { event: 'leave' }, ({ leftPresences }) => {
        leftPresences.forEach((presence: any) => {
          onlineUsers.value.delete(presence.user_id)
        })
      })
      .subscribe(async (status) => {
        if (status === 'SUBSCRIBED') {
          const { data: { user } } = await supabase.auth.getUser()
          if (user) {
            await channel.track({ user_id: user.id })
          }
        }
      })

    return { channel, unsubscribe: () => subscription.unsubscribe() }
  }

  function initializeOnlineStatus(): void {
    channelInstance = subscribeToOnlineStatus()
  }

  async function cleanupOnlineStatus(): Promise<void> {
    if (channelInstance) {
      const { data: { user } } = await supabase.auth.getUser()
      if (user) {
        await channelInstance.channel.untrack()
      }
      channelInstance.unsubscribe()
      channelInstance = null
    }
  }

  return {
    onlineUsers,
    isUserOnline,
    initializeOnlineStatus,
    cleanupOnlineStatus,
  }
})

export type UserOnlineStatusStore = ReturnType<typeof useUserOnlineStatusStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserConversationsStore, import.meta.hot))
}

<script setup lang="ts">
const props = defineProps<{
    userId?: string
}>()

const { userId } = toRefs(props)

const userUserOnlineStatus = useUserOnlineStatusStore()
const { isUserOnline } = storeToRefs(userUserOnlineStatus)

const { profile, fetchUserProfile, loading } = useUserProfile()

onMounted(() => {
    if (userId.value) {
      fetchUserProfile(userId.value)
    }
})
</script>

<template>
  <div class="relative inline-block w-10 h-10">
    <Avatar class="w-full h-full overflow-hidden bg-input">
      <AvatarImage v-if="!loading" :src="profile?.avatar_url || constructFallbackAvatarUrl(profile?.full_name || '')" class="w-full h-full object-cover" />
      <Skeleton v-if="loading" class="w-full h-full" />
      <!-- <Icon v-else name="lucide:user" class="h-6 w-6 text-background absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" /> -->
    </Avatar>
    <span
      v-if="userId"
      class="absolute bottom-0.5 -right-0.5 block h-3 w-3 rounded-full border-2 border-background"
      :class="isUserOnline(userId) ? 'bg-green-500' : 'bg-gray-300'"
    />
  </div>
</template>
